<template>
    <div>
        <datatable :columns="columnsClient" :rows="clients">
            <th slot="thead-tr">
                Thao Tác
            </th>
            <template slot="tbody-tr" slot-scope="props">
                <td>
                    <button
                        @click="editUser(props.row)"
                        type="button"
                        class="btn bg-orange btn-xs mr-2"
                        data-toggle="modal"
                        href="#level-modal"
                    >
                        <i class="fa fa-user-circle-o"></i>
                        Cài Đặt Đại Lý
                    </button>
                    <button
                        @click="editUser(props.row)"
                        type="button"
                        class="btn btn-dark-blue btn-xs mr-2"
                        data-toggle="modal"
                        href="#modal-update"
                    >
                        <i class="fa fa-list"></i>
                        Chỉnh Sửa
                    </button>
                    <button @click="deleteUser(props.row)" type="button" class="btn bg-danger text-white btn-xs">
                        <i class="fa fa-close"></i>
                        Xóa
                    </button>
                </td>
            </template>
        </datatable>
        <clientchangelevel :levelList="levelList" :selectedUser="selectedUser"></clientchangelevel>
        <clientupdate :selectedUser="selectedUser"></clientupdate>
    </div>
</template>
<script>
import datatable from "../../../components/datatable/datatable"
import clientchangelevel from "./client-change-level"
import clientupdate from "./client-update"
import { catchError } from "../../../helpers"
import { deleteUser } from "@/api/admin-agency-lv2"
export default {
    name: "client-user",
    components: {
        datatable,
        clientchangelevel,
        clientupdate
    },
    props: {
        levelList: Array,
        clients: Array
    },
    data() {
        return {
            columnsClient: [
                { label: "#", field: "index" },
                // { label: "Id", field: "id" },
                { label: "UserName", field: "username" },
                { label: "Tiền (vnđ)", field: "coin", numeric: true },
                { label: "Level", field: "level_html", html: true },
                { label: "Email", field: "email", html: true },
                { label: "SDT", field: "phone", html: true },
                { label: "Ngày Tạo", field: "created_at", timeago: true }
            ],
            selectedUser: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        site() {
            return this.$store.state.agency.site
        },
        agency() {
            return this.$store.state.agency
        }
    },
    methods: {
        editUser: function(client) {
            this.selectedUser = Object.assign({}, client)
        },
        deleteUser: async function(selectedUser) {
            let that = this
            let cancel = false
            await this.$swal({
                title: "Xác nhận",
                text: "Bạn có chắc chắn muốn xóa tài khoản " + selectedUser.username,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    cancel = true
                }
            })
            if (cancel) {
                let data = await deleteUser(selectedUser.id)
                if (data.status === 200 && data.success) {
                    this.$swal("Hoàn thành", "Đã xóa thành công tài khoản " + selectedUser.username, "success")
                    that.$store.commit("APP_RELOAD")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        }
    }
}
</script>
