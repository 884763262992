<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <div class="text-right">
            <button data-toggle="modal" href="#client-trans-coin" type="button" class="btn bg-orange btn-md mr-2">
                Nạp tiền
            </button>
            <button data-toggle="modal" href="#level-setting" type="button" class="btn btn-dark-blue btn-md">
                Cài đặt phân quyền đại lý
            </button>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-md-6 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo username:</h6>
                    </div>
                    <div class="col-md col-12 mt-2 mt-md-0 pl-md-0">
                        <v-select
                            class="input-light"
                            label="username"
                            v-model="selectUser"
                            :options="clients"
                            @search="onSearch"
                            placeholder="Nhập username cần tìm"
                        >
                            <template slot="no-options">
                                Không tìm thấy danh sách User...
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-md-0 mt-2">
                <div class="input-group">
                    <div class="input-group-append bg-gray">
                        <span class="input-group-text font-bold bg-gray">
                            Hiển thị
                        </span>
                    </div>
                    <select class="form-control select-left bg-gray" v-model="limit" @change="getAllUser()">
                        <option value="100">100 User</option>
                        <option value="200">200 User</option>
                        <option value="300">300 User</option>
                        <option value="500">500 User</option>
                        <option value="1000">1000 User</option>
                        <option value="3000">3000 User</option>
                        <option value="5000">5000 User</option>
                        <option value="9999">10000 User</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <button @click="getAllUser()" class="form-control mt-2 mt-md-0 btn-md btn btn-dark-blue" type="submit">
                    <i class="fas fa-sync-alt mr-1"></i>Tải lại danh sách User
                </button>
            </div>
        </div>
        <clientlistuser :levelList="levelList" :clients="clients"></clientlistuser>
        <clienttranscoin @getAllUser="getAllUser()" :clients="clients"></clienttranscoin>
        <clientlevelsetting :levelList="levelList"></clientlevelsetting>
    </div>
</template>
<script>
import clientlistuser from "./client-list-user"
import clienttranscoin from "./client-trans-coin"
import clientlevelsetting from "./client-level-setting"
import { getListLevels, getAllUser } from "../../../api/admin-agency-lv2"
import _ from "lodash"
export default {
    components: {
        clientlistuser,
        clienttranscoin,
        clientlevelsetting
    },
    data() {
        return {
            limit: 100,
            levelList: [],
            clients: [],
            selectUser: ""
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        site() {
            return this.$store.state.agency.site
        },
        curency() {
            return this.$store.state.agency.curency || "Xu"
        },
        reload() {
            return this.$store.state.app.reload
        },
        agency() {
            return this.$store.state.agency
        }
    },
    watch: {
        reload() {
            this.getListLevels()
            this.getAllUser()
        }
    },
    created() {
        this.getListLevels()
        this.getAllUser()
    },
    methods: {
        onSearch(search, loading) {
            if (search.length > 0) {
                loading(true)
                this.search(loading, search, this)
            }
        },
        search: _.debounce(async (loading, search, vm) => {
            await vm.getAllUser(search)
            loading(false)
        }, 500),
        getListLevels: async function() {
            let data = await getListLevels()
            this.levelList = data.data
        },
        getAllUser: async function(username = null) {
            this.clients = []
            let param = username ? "&username=" + username : ""
            let data = await getAllUser(this.limit, param)
            if (data && data.status === 200) {
                let totalCoin = 0
                this.clients = data.data.map((client, index) => {
                    client.index = index + 1
                    totalCoin += client.coin
                    client.coin_html = client.coin.toLocaleString("it-IT") + " "
                    let defaultLevelName = ""
                    for (let level of this.levelList) {
                        if (level.default === 1) {
                            defaultLevelName = level.name
                        }
                    }
                    if (client.levels) {
                        client.level_html = client.levels.name
                    } else client.level_html = defaultLevelName
                    return client
                })
                this.totalCoin = totalCoin
            }
        }
    }
}
</script>
