<template>
    <div class="modal fade" id="modal-update">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Cập Nhật Khách Hàng</h4>
                </div>
                <form class="form-horizontal" @submit.stop.prevent="updateInfo">
                    <div class="modal-body py-2">
                        <div class="form-group">
                            <label>Tên Tài Khoản</label>
                            <div>
                                <input
                                    type="text"
                                    class="form-control form-control-line username-text"
                                    v-model="user_update.username"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Số Coin</label>
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control form-control-line username-text select-right"
                                    v-model.number="user_update.coin"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ currency }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="example-email">Email</label>
                            <div>
                                <input
                                    type="email"
                                    class="form-control form-control-line"
                                    v-model="user_update.email"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="example-email">Facebook ID (Nhập Link Facebook của khách hàng)</label>
                            <div>
                                <input type="text" class="form-control form-control-line" v-model="user_update.fbid" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Mật khẩu mới</label>
                            <div>
                                <input
                                    type="password"
                                    class="form-control form-control-line"
                                    v-model="user_update.password"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Nhập lại mật khẩu mới</label>
                            <div>
                                <input
                                    type="password"
                                    class="form-control form-control-line"
                                    v-model="user_update.password_confirm"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Họ và Tên</label>
                            <div>
                                <input type="text" class="form-control form-control-line" v-model="user_update.name" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Số Điện Thoại</label>
                            <div>
                                <input type="text" class="form-control form-control-line" v-model="user_update.phone" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <div class="col-sm-12">
                                <button type="button" class="btn btn-secondary px-4 mr-2" data-dismiss="modal">
                                    Đóng
                                </button>
                                <button :class="['btn btn-dark-blue px-4']" type="submit">Lưu Thông Tin</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { updateUser } from "@/api/admin-agency-lv2"
import { catchError } from "../../../helpers"
export default {
    name: "client-update-modal",
    props: {
        selectedUser: Object
    },
    data() {
        return {
            user_update: {}
        }
    },
    computed: {
        currency() {
            return this.$store.state.agency.site.currency || "Xu"
        },
        agency() {
            return this.$store.state.agency
        }
    },
    watch: {
        selectedUser() {
            this.user_update = Object.assign({}, this.selectedUser)
        }
    },
    methods: {
        updateInfo: async function() {
            if (this.user_update.password !== "" && this.user_update.password !== this.user_update.password_confirm) {
                this.$toastr.error("Mật khẩu mới và mật khậu nhập lại không khớp")
                return
            }
            let postData = {
                id: this.user_update.id
            }
            for (const key in this.user_update) {
                if (this.user_update.hasOwnProperty(key) && this.selectedUser[key]) {
                    if (this.user_update[key] !== this.selectedUser[key]) {
                        postData[key] = this.user_update[key]
                    }
                } else {
                    postData[key] = this.user_update[key]
                }
            }
            let data = await updateUser(this.selectedUser.id, postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", "Cập nhật thông tin thành công", "success")
                $("#modal-update").modal("hide")
                this.$store.commit("APP_RELOAD")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
                $("#modal-update").modal("hide")
            }
        }
    }
}
</script>
